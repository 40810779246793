import React from 'react';
import SEO from '../components/seo';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import Pageheader from '../Utils/pageheader';
import ViewPdf from '../Utils/viewPdf';

const Incorporation = () => {
    return (
        <>
            <SEO title="Incorporation" />
            <MDBContainer className="mt-4">
                <h3 className="title font-weight-bolder text-secondary">Incorporation</h3>
                <Pageheader />
                <MDBRow>
                    <MDBCol className="mx-auto mt-4">

                        <ViewPdf title="Incorporation" pdf='https://iowaht.s3.us-east-2.amazonaws.com/pdf/Restated_Articles_of_Inc.pdf' />

                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </>
    );
};

export default Incorporation;